/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  TwitterShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  TwitterIcon,
  LinkedinShareButton,
} from "react-share";

import SEO from "../components/seo";

const useStyles = makeStyles(() => ({
  sidebar: {
    width: "45px",
    position: "fixed",
    zIndex: "1",
    top: "200px",
    left: "10px",
    background: "#fff",
    overflowX: "hidden",
    padding: "8px 0",
  },
  shareButton: {
    marginBottom: "10px",
    cursor: "pointer",
  },
}));

const StyledBlogPage = styled.div`
  figure {
    overflow-x: auto;
    padding: 0px;
    margin: 0px;
  }
  img {
    border: 1px solid #ccc;
  }
  figcaption {
    text-align: center;
    margin-top: 10px;
    font-size: small;
  }
  h2 {
    padding-top: 20px;
    font-size: 1.2rem;
  }
  iframe {
    width: 100%;
  }
  h1 {
    font-size: 2rem;
  }
`;

export default (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:950px)");
  const { seo_title, meta_description, body } = props.pageContext;

  return (
    <>
      {seo_title && <SEO title={seo_title} description={meta_description} />}

      <Container
        maxWidth="md"
        fullWidth
        style={{
          paddingBottom: "50px",
        }}
      >
        <StyledBlogPage>
          <Typography component="div">
            <Box
              textAlign="center"
              component="h1"
              fontFamily="DM Serif Text"
              color="#3d3d3d"
              fontSize="h3.fontSize"
            >
              {seo_title}
            </Box>
            <Box color="#3d3d3d" marginTop="10px" textAlign="left">
              {body && (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                />
              )}
            </Box>
          </Typography>
          <div hidden={!matches} className={classes.sidebar}>
            <TwitterShareButton
              url={props.location.href}
              className={classes.shareButton}
              title={seo_title}
              via="lab_log"
            >
              <TwitterIcon round size={42} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={props.location.href}
              className={classes.shareButton}
            >
              <LinkedinIcon round size={42} />
            </LinkedinShareButton>
            <FacebookShareButton
              url={props.location.href}
              className={classes.shareButton}
              quote={seo_title}
            >
              <FacebookIcon round size={42} />
            </FacebookShareButton>
          </div>
        </StyledBlogPage>
      </Container>
    </>
  );
};
